<template>
    <dataset v-slot="{ ds }" :ds-data="contracts">
      <!-- :ds-filter-fields="{ onlineStatus: onlineFilter, name: startsWithFilter }"
      :ds-sortby="[sortFirstName]"
      :ds-search-in="['balance', 'birthdate', 'name', 'company', 'email', 'phone', 'address', 'favoriteAnimal']"
      :ds-search-as="{ birthdate: searchAsEuroDate }" -->
      <div class="row mb-2">
        <div class="col-md-6 mb-2 mb-md-0">
          <dataset-show />
        </div>
        <div class="col-md-6">
          <dataset-search ds-search-placeholder="Recherche ..." :wait="300" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <dataset-item
            class="form-row mb-3"
            style="overflow-y: auto; max-height: 400px"
          >
            <template #default="{ row, rowIndex }">
                <div class="col-md-4">
              <router-link :to="{ name: 'invoicing.contract.suppliers_invoices', params: { uid: row.uuid }, }" >
                  <div class="card mb-2">
                    <div class="card-body pt-3 pb-2 px-3">
                      <h5 class="card-title text-truncate mb-2" :title="`Index: ${rowIndex}`">
                        <a href="">Contrat N°: #{{ row.ref }}</a>
                      </h5>
                      <h6 class="card-subtitle text-truncate text-muted mt-2 mb-2">
                        <span class="mt-2">Type de contrat: <b>{{ row.type }}</b></span>
                      </h6>
                      <h6 class="card-subtitle text-truncate text-muted mt-2 mb-2">
                        <span class="mt-2">Prestataire: <b>{{ row.supplier.prestname }}</b></span>
                      </h6>
                      <h6 class="card-subtitle text-truncate text-muted mt-2 mb-2">
                        <span class="mt-2">Prestations:: <b>{{ row.supplier.prestname }}</b></span>
                      </h6>
                      <h6 class="card-subtitle text-truncate text-muted mt-2 mb-2 float-right">
                        <span class="mt-2 text-success">Montant Global: <b>{{ row.amounts.planned_amount.withCurrency }}</b></span>
                      </h6>
                    </div>
                  </div>
              </router-link>
                </div>
            </template>
            <template #noDataFound>
              <div class="col-md-12 pt-2">
                <p class="text-center">Aucun contrat n'est trouvé</p>
              </div>
            </template>
          </dataset-item>
        </div>
      </div>
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-center"
      >
        <dataset-info class="mb-2 mb-md-0" v-if="contracts.length > 0"/>
        <dataset-pager v-if="contracts.length > 0"/>
      </div>
    </dataset>
</template>

<script>
import {
  filterList,
  clone,
  isoDateToEuroDate,
  searchAsEuroDate,
} from "../../../utilities";
import { debounce } from "../../../helpers";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetInfo from "vue-dataset/dist/es/DatasetInfo.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetSearch from "vue-dataset/dist/es/DatasetSearch.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
export default {
    components: {
        Dataset,
        DatasetItem,
        DatasetInfo,
        DatasetPager,
        DatasetSearch,
        DatasetShow,
    },
    props : {
        contracts: [],
    },
    created() {
        this.startWithInput = debounce((e) => {
        this.startsWith = e.target.value;
        }, 300);
    },

    methods: {
        filterList,
        isoDateToEuroDate,
        searchAsEuroDate,
        
        startsWithFilter(value) {
            return value.toLowerCase().startsWith(this.startsWith.toLowerCase());
        },
    },
}
</script>